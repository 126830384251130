/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import Spritesheet from './Spritesheet';

import spritesheet from './img/head.json';
import image from './img/head.png';

const shapesMap = {
    citron: 'Visage01/Visage_01_dessin',
    rond: 'Visage02/Visage_02_dessin',
    carre: 'Visage03/Visage_03_dessin',
    triangle: 'Visage04/Visage_04_dessin',
    ovale: 'Visage05/Visage_05_dessin',
};

export const shapes = Object.keys(shapesMap);

const propTypes = {
    shape: PropTypes.oneOf(shapes),
};

const defaultProps = {
    shape: shapes[0],
};

function Head({ shape, ...props }) {
    return (
        <Spritesheet
            {...props}
            image={image}
            spritesheet={spritesheet}
            animation={shapesMap[shape || 'citron']}
            loop
        />
    );
}

Head.propTypes = propTypes;
Head.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Head;
