/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import Spritesheet from './Spritesheet';

import spritesheet from './img/clothes.json';
import image from './img/clothes.png';

const clothesMap = {
    laine: 'outfit_laine_dessin_01.png',
    salopette: 'outfit_salopette_dessin_01.png',
    tshirt: 'outfit_t-shirt_dessin_01.png',
    robe: 'outfit_robe_dessin_01.png',
    cardignan: 'outfit_cardigan_dessin_01.png',
};

export const clothes = Object.keys(clothesMap);

const propTypes = {
    clothes: PropTypes.oneOf(clothes),
};

const defaultProps = {
    clothes: clothes[0],
};

function Clothes({ clothes: clothesName, ...props }) {
    return (
        <Spritesheet
            {...props}
            image={image}
            spritesheet={spritesheet}
            texture={clothesMap[clothesName]}
            loop
        />
    );
}

Clothes.propTypes = propTypes;
Clothes.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Clothes;
