/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import Spritesheet from './Spritesheet';

import spritesheet from './img/face.json';
import image from './img/face.png';

const expressionsMap = {
    eating: 'Boire/Expression_boire_dessin',
    happy: 'Content/Expression_content_dessin',
    sleeping: 'Dodo/Expression_dodo_dessin',
    angry: 'Fru/Expression_fru_dessin',
    love: 'Love/ Expression_love_dessin',
    neutral: 'Neutre/Expression_neutre_dessin',
    normal: 'Normal/Expression_normal_dessin',
    crying: 'Pleur/Expression_pleur_dessin',
    laugh: 'Rire/Expression_rire_dessin',
    suce: 'Suce/Expression_suce_dessin',
    surprise: 'Surpris/Expression_surpris_dessin',
};

export const expressions = Object.keys(expressionsMap);

const propTypes = {
    expression: PropTypes.oneOf(expressions),
};

const defaultProps = {
    expression: expressions[0],
};

function Face({ expression, ...props }) {
    return (
        <Spritesheet
            {...props}
            image={image}
            spritesheet={spritesheet}
            animation={expressionsMap[expression]}
            speed={0.08}
            loop
        />
    );
}

Face.propTypes = propTypes;
Face.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Face;
