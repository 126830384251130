import { useSpring, useSpringValue } from '@react-spring/web';
import { useCallback, useEffect, useState } from 'react';

import { HUNGRY_THRESHOLD, TIRED_THRESHOLD } from './computeState';

const defaultState = {
    shit: 0,
    hungry: 0,
    angry: 0,
    tired: 0,
    surprise: 0,
    sleeping: 0,
    love: 0,
    laughing: 0,
    cheese: 0,
    eating: 0,
    suce: 0,
    music: 0,
    toy: 0,
    diaper: 0,
    points: 0,
};

const noObject = {
    suce: 0,
    music: 0,
    toy: 0,
};

const resetMood = {
    surprise: 0,
    sleeping: 0,
    love: 0,
    laughing: 0,
};

export default function useGotchiState({ paused = false } = {}) {
    const [points, setPoints] = useState(0);
    const [state, api] = useSpring(() => ({
        from: { ...defaultState },
        to: { ...defaultState },
        pause: paused,
        config: (key) => {
            if (key === 'sleeping') {
                return {
                    duration: 10000,
                };
            }
            if (key === 'shit') {
                return {
                    duration: 10000,
                };
            }
            if (key === 'tired') {
                return {
                    duration: 30000,
                };
            }
            if (key === 'hungry') {
                return {
                    duration: 20000,
                };
            }
            if (key === 'music') {
                return {
                    duration: 5000,
                };
            }
            if (key === 'suprise') {
                return {
                    duration: 2000,
                };
            }
            if (key === 'laughing') {
                return {
                    duration: 3000,
                };
            }
            return {
                duration: 1000,
            };
        },
    }));

    useEffect(() => {
        if (paused) {
            api.start({ ...defaultState, immediate: true });
        } else {
            api.start({
                from: { ...defaultState },
                to: { ...defaultState, hungry: 1, tired: 1 },
                pause: false,
            });
        }
    }, [paused, api]);

    const wakeUp = useCallback(() => {
        api.start({
            sleeping: 0,
            immediate: true,
        });
    }, [api]);

    const sleep = useCallback(() => {
        api.start({
            from: {
                sleeping: 1,
                tired: 0,
                hungry: 0,
            },
            to: {
                sleeping: 0,
                tired: 1,
                hungry: 1,
            },
        });
    }, [api]);

    const feed = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                hungry: 0,
                shit: 0,
                eating: 1,
            },
            to: {
                hungry: 1,
                shit: 1,
                eating: 0,
            },
        });
    }, [api, state]);

    const changeDiaper = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                ...noObject,
                shit: 0,
                angry: state.angry.get(),
                diaper: 1,
            },
            to: {
                ...noObject,
                shit: 0,
                angry: 0,
                diaper: 0,
            },
        });
    }, [api, wakeUp]);

    const canSleep = useCallback(() => {
        const insatisfaction = Math.max(state.hungry.get(), state.shit.get());
        const tired = state.tired.get();
        return insatisfaction < HUNGRY_THRESHOLD && tired > TIRED_THRESHOLD;
    }, [api]);

    const cuddle = useCallback(() => {
        if (canSleep()) {
            sleep();
            return;
        }
        api.start({
            from: {
                love: 1,
                angry: state.angry.get(),
                hungry: Math.max(state.hungry.get() - 0.1, 0),
            },
            to: {
                love: 0,
                angry: 0,
                hungry: 1,
            },
        });
    }, [api, canSleep, sleep]);

    const giveSuce = useCallback(() => {
        api.start({
            from: {
                ...noObject,
                suce: 1,
                tired: Math.max(state.tired.get() - 0.1, 0),
            },
            to: {
                ...noObject,
                suce: 0,
                tired: 1,
            },
        });
        if (canSleep()) {
            sleep();
        }
    }, [api, canSleep, sleep]);

    const makeLaugh = useCallback(() => {
        api.start({
            from: {
                laughing: 1,
            },
            to: {
                laughing: 0,
            },
        });
    }, [api]);

    const giveToy = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                ...noObject,
                angry: state.angry.get(),
                hungry: Math.max(state.hungry.get() - 0.1, 0),
                tired: Math.min(state.tired.get() + 0.1, 1),
                toy: 1,
                laughing: 1,
            },
            to: {
                ...noObject,
                angry: 0,
                hungry: 1,
                tired: 1,
                toy: 0,
                laughing: 0,
            },
        });
    }, [api, wakeUp]);

    const putMusic = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                ...noObject,
                laughing: 1,
                music: 1,
                hungry: Math.max(state.tired.get() - 0.1, 0),
                tired: Math.min(state.tired.get() + 0.2, 1),
            },
            to: {
                ...noObject,
                laughing: 0,
                music: 0,
                hungry: 1,
                tired: 1,
            },
        });
    }, [api, wakeUp]);

    const throwCheese = useCallback(() => {
        wakeUp();
        api.start({
            from: {
                ...noObject,
                ...resetMood,
                surprise: 1,
                angry: 0,
                cheese: 1,
            },
            to: {
                ...noObject,
                ...resetMood,
                surprise: 0,
                angry: 1,
                cheese: 0,
            },
        });
    }, [api, wakeUp]);

    const start = useCallback(() => {
        api.start({
            from: { ...defaultState },
            to: { ...defaultState, hungry: 1, tired: 1 },
        });
    }, [api]);

    const reset = useCallback(() => {
        api.start({
            ...defaultState,
            immediate: true,
        });
    }, [api]);

    const random = useCallback(() => {
        api.start({
            shit: Math.random() < 0.1 ? 1 : 0,
            sleeping: Math.random() < 0.1 ? 1 : 0,
            hungry: Math.random(),
            tired: Math.random(),
            angry: Math.random() < 0.1 ? 1 : 0,
            immediate: true,
        });
    }, [api]);

    const addPoints = useCallback(
        (newPoints) => {
            setPoints((currentPoints) => currentPoints + newPoints);
        },
        [setPoints, points],
    );

    const removePoints = useCallback(
        (newPoints) => {
            setPoints((currentPoints) => Math.max(currentPoints - newPoints, 0));
        },
        [setPoints],
    );

    useEffect(() => {
        api.start({
            points,
        });
    }, [points]);

    return {
        state,
        feed,
        changeDiaper,
        cuddle,
        giveToy,
        giveSuce,
        throwCheese,
        putMusic,
        reset,
        random,
        start,
        makeLaugh,
        addPoints,
        removePoints,
    };
}
