import { Howl } from 'howler';
import { useMemo, useRef } from 'react';

function useAudio(src, opts) {
    const { loop = false, volume = 0.5, sprite } = opts || {};
    const soundRef = useRef(null);
    const sound = useMemo(() => {
        let { current: currentSound = null } = soundRef;
        if (currentSound === null) {
            currentSound = new Howl({
                src,
                loop,
                volume,
                sprite,
            });
            soundRef.current = currentSound;
        } else {
            currentSound.loop(loop);
            currentSound.volume(volume);
        }

        return currentSound;
    }, [src, loop, volume, sprite]);

    return sound;
}

export default useAudio;
