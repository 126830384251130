import { useEffect, useRef, useState } from 'react';

import { Spritesheet, Texture } from 'pixi.js';

export default function useSpritesheetTextures(spritesheet, image) {
    const [textures, setTextures] = useState(null);
    const [animations, setAnimations] = useState(null);
    const baseTextureRef = useRef(null);

    useEffect(() => {
        if (baseTextureRef.current !== null) {
            baseTextureRef.current.destroy();
        }
        baseTextureRef.current = Texture.from(image);
        const sheet = new Spritesheet(baseTextureRef.current, spritesheet);
        sheet.parse().then(() => {
            setTextures(sheet.textures);
            setAnimations(sheet.animations);
        });
    }, [spritesheet, image]);
    return {
        animations,
        textures,
    };
}
