/* eslint-disable react/jsx-props-no-spreading */
import { AnimatedSprite, Container, Sprite } from '@pixi/react';
import PropTypes from 'prop-types';
import React from 'react';

import useSpritesheetTextures from './useSpritesheetTextures';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    paused: PropTypes.bool,
    speed: PropTypes.number,
    loop: PropTypes.false,
    // eslint-disable-next-line react/forbid-prop-types
    spritesheet: PropTypes.object.isRequired,
    image: PropTypes.string.isRequired,
    animation: PropTypes.string,
    texture: PropTypes.string,
};

const defaultProps = {
    width: 0,
    height: 0,
    paused: false,
    speed: 0.1,
    loop: false,
    animation: null,
    texture: null,
};

function Spritesheet({ width, height, paused, speed, spritesheet, image, animation, texture, loop }) {
    const { textures = null, animations = null } = useSpritesheetTextures(
        spritesheet,
        image,
        animation,
    );
    if (textures === null) {
        return null;
    }
    return (
        <Container width={width} height={height}>
            {animation !== null ? (
                <AnimatedSprite
                    key={`animation-${animation}`}
                    animationSpeed={speed}
                    isPlaying={!paused}
                    textures={animations[animation]}
                    anchor={0}
                    width={width}
                    height={height}
                    loop={loop}
                />
            ) : (
                <Sprite
                    key={`sprite-${texture}`}
                    texture={textures[texture]}
                    anchor={0}
                    width={width}
                    height={height}
                />
            )}
        </Container>
    );
}

Spritesheet.propTypes = propTypes;
Spritesheet.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Spritesheet;
