/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import Spritesheet from './Spritesheet';

import spritesheet from './img/ambiance.json';
import image from './img/ambiance.png';

const ambiancesMap = {
    love: 'Amour/Ambiance_amour_dessin',
    sleep: 'Dodo/Ambiance_dodo_dessin',
    music: 'Musique/Ambiance_musique_dessin',
    smell: 'Odeur/Ambiance_odeur_dessin',
};

export const ambiances = Object.keys(ambiancesMap);

const propTypes = {
    ambiance: PropTypes.oneOf(ambiances),
};

const defaultProps = {
    ambiance: ambiances[0],
};

function Ambiance({ ambiance, ...props }) {
    return ambiance !== null ? (
        <Spritesheet
            {...props}
            image={image}
            spritesheet={spritesheet}
            animation={ambiancesMap[ambiance]}
            speed={0.1}
            loop
        />
    ) : null;
}

Ambiance.propTypes = propTypes;
Ambiance.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Ambiance;
