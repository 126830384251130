import { useUser } from '@folklore/auth';
import {
    useQuery,
    keepPreviousData as previousPlaceholder,
    useQueryClient,
    useMutation,
} from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function useSubmissions(query = null, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { id: userId = null } = useUser() || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['submissions', query, userId],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.submissions.get({ paginated: false, ...queryParam }, null, null, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    // const { data: items, pagination } = data || {};

    return data !== null
        ? {
              items: data,
              //   pagination,
              ...queryResult,
          }
        : queryResult;
}

export function useSubmission(id, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { id: userId = null } = useUser() || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['submission', id, userId],
        queryFn: ({ queryKey: [, idParam], signal }) =>
            api.submissions.findById(idParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    // const { data: items, pagination } = data || {};

    return data !== null
        ? {
              submission: data,
              //   pagination,
              ...queryResult,
          }
        : queryResult;
}

export function useDocumentSubmission(documentId, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { id: userId = null } = useUser() || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['submission_document', documentId, userId],
        queryFn: ({ queryKey: [, idParam], signal }) =>
            api.submissions.findByDocument(idParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    // const { data: items, pagination } = data || {};

    return data !== null
        ? {
              submission: data,
              //   pagination,
              ...queryResult,
          }
        : queryResult;
}

export function useUrlSubmission(url, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { id: userId = null } = useUser() || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['submission_url', url, userId],
        queryFn: ({ queryKey: [, urlParam], signal }) =>
            api.submissions.findByUrl(urlParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    // const { data: items, pagination } = data || {};

    return data !== null
        ? {
              submission: data,
              //   pagination,
              ...queryResult,
          }
        : queryResult;
}

export function useMutateSubmission(opts = {}) {
    const { onSuccess = null, ...otherOpts } = opts || {};
    const queryClient = useQueryClient();
    const api = useApi();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.submissions.submit(data),
        onSuccess: (newData) => {
            const { user: newUser, submission: newSubmission } = newData;
            const { id, document = null } = newSubmission || {};
            const { id: documentId } = document || {};
            queryClient.setQueryData(['user'], newUser);
            queryClient.setQueryData(['submission', id], newSubmission);
            if (documentId !== null) {
                queryClient.setQueryData(['submission_document', documentId], newSubmission);
            }

            if (onSuccess !== null) {
                onSuccess(newData);
            }
        },
        ...otherOpts,
    });

    return {
        submit: mutate,
        submitAsync: mutateAsync,
        ...mutationResult,
    };
}
