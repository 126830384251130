/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import Spritesheet from './Spritesheet';

import spritesheet from './img/hair.json';
import image from './img/hair.png';

const hairsMap = {
    casquette: 'Chapeau_casquette_dessin01.png',
    carotte: 'Cheveux_carotte_dessin01.png',
    clean: 'Cheveux_coiffés_dessin01.png',
    electrique: 'Cheveux_électrique_dessin01.png',
    ligne: 'Cheveux_ligne_dessin01.png',
    tuque: 'Cheveux_tuque_dessin01.png',
    boucle: 'Cheveux_boucle.png'
};

export const hairs = Object.keys(hairsMap);

const propTypes = {
    hair: PropTypes.oneOf(hairs),
};

const defaultProps = {
    hair: hairs[0],
};

function Hair({ hair, ...props }) {
    return (
        <Spritesheet {...props} image={image} spritesheet={spritesheet} texture={hairsMap[hair]} loop />
    );
}

Hair.propTypes = propTypes;
Hair.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Hair;
