/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from './Button';

import styles from './circle-button.module.css';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

function CircleButton({ className, children, ...props }) {
    return (
        <Button className={classNames([styles.container, className])} {...props}>
            <span className={styles.shape}>{children}</span>
        </Button>
    );
}

CircleButton.propTypes = propTypes;
CircleButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default CircleButton;
