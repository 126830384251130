/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import Spritesheet from './Spritesheet';

import spritesheet from './img/objects.json';
import image from './img/objects.png';

const accessoriesMap = {
    biberon: 'Biberon/objet_biberon_dessin',
    cheese: 'Cheese/objet_tranchedefromage_dessin',
    lunette: 'Lunette/objet_lunette_type',
    toy: 'Sophie/objet_sophie_dessin',
    diaper: 'Couche/objet_couche_dessin',
};

export const accessories = Object.keys(accessoriesMap);

const propTypes = {
    accessory: PropTypes.oneOf(accessories),
};

const defaultProps = {
    accessory: null,
};

function Accessory({ accessory, ...props }) {
    return accessory !== null ? (
        <Spritesheet
            {...props}
            image={image}
            spritesheet={spritesheet}
            animation={accessoriesMap[accessory]}
            speed={0.05}
        />
    ) : null;
}

Accessory.propTypes = propTypes;
Accessory.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Accessory;
