/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import { animatedState } from './computeState';

const propTypes = {
    children: PropTypes.node.isRequired,
    state: PropTypes.shape({}),
};

const defaultProps = {};

function KidState({ state, children, ...props }) {
    return React.cloneElement(children, {
        ...state,
        ...Object.keys(props).reduce(
            (acc, key) =>
                props[key] !== null
                    ? {
                          ...acc,
                          [key]: props[key],
                      }
                    : acc,
            {},
        ),
    });
}

KidState.propTypes = propTypes;
KidState.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default animatedState(KidState);
