/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { animatedState } from './computeState';

import styles from './gauge.module.css';

const propTypes = {
    state: PropTypes.shape({
        happiness: PropTypes.number,
        hungry: PropTypes.number,
        shit: PropTypes.number,
        tired: PropTypes.number,
        points: PropTypes.number,
        isHungry: PropTypes.bool,
        hasShit: PropTypes.bool,
        isTired: PropTypes.bool,
    }),
    className: PropTypes.string,
};

const defaultProps = {
    state: null,
    className: null,
};

function Gauge({ state, className }) {
    const {
        happiness = 1,
        hungry = 0,
        tired = 0,
        points = 0,
        isHungry = false,
        hasShit = false,
        isTired = false,
    } = state;

    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.row}>
                <div className={styles.label}>Bonheur</div>
                <div className={styles.progress}>
                    <div
                        className={styles.bar}
                        style={{
                            width: `${happiness * 100}%`,
                        }}
                    />
                </div>
            </div>
            <div
                className={classNames([
                    styles.row,
                    {
                        [styles.urgent]: isTired && !isHungry && !hasShit,
                    },
                ])}
            >
                <div className={styles.label}>Fatigue</div>
                <div className={styles.progress}>
                    <div
                        className={styles.bar}
                        style={{
                            width: `${tired * 100}%`,
                        }}
                    />
                </div>
            </div>
            <div
                className={classNames([
                    styles.row,
                    {
                        [styles.urgent]: isHungry || hasShit,
                    },
                ])}
            >
                <div className={styles.label}>{hasShit ? 'Caca' : 'Faim'}</div>
                <div className={styles.progress}>
                    <div
                        className={styles.bar}
                        style={{
                            width: `${hungry * 100}%`,
                        }}
                    />
                </div>
            </div>
            <div className={styles.points}>
                {`${points < 0 ? '-' : ''}${`${Math.abs(Math.round(points))}`.padStart(6, '0')}`
                    .split('')
                    .map((it) => (
                        <span>{it}</span>
                    ))}
            </div>
        </div>
    );
}

Gauge.propTypes = propTypes;
Gauge.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default animatedState(Gauge);
