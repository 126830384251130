/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BasicButton from '../../components/buttons/BasicButton';

import styles from './button.module.css';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

function Button({ className, children, ...props }) {
    return (
        <BasicButton className={classNames([styles.container, className])} {...props}>
            {children}
        </BasicButton>
    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default Button;
