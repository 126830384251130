/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import CircleButton from './CircleButton';

import styles from './toolbar.module.css';
import { animatedState } from './computeState';

const propTypes = {
    playing: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    buttons: PropTypes.array,
    onClick: PropTypes.func.isRequired,
    state: PropTypes.shape({
        happiness: PropTypes.number,
        hungry: PropTypes.number,
        shit: PropTypes.number,
    }),
    className: PropTypes.string,
};

const defaultProps = {
    playing: false,
    buttons: [],
    state: null,
    className: null,
};

function Toolbar({ playing, buttons, state, onClick, className }) {
    const currentToolbar = useMemo(() => {
        if (!playing) {
            return buttons;
        }
        return [
            buttons
                .filter(({ index }) => index === 0)
                .sort(({ priority: a }, { priority: b }) => {
                    const priorityA = a(state);
                    const priorityB = b(state);
                    if (priorityA === priorityB) {
                        return 0;
                    }
                    return priorityA > priorityB ? 1 : -1;
                })[0],
            buttons
                .filter(({ index }) => index === 1)
                .sort(({ priority: a }, { priority: b }) => {
                    const priorityA = a(state);
                    const priorityB = b(state);
                    if (priorityA === priorityB) {
                        return 0;
                    }
                    return priorityA > priorityB ? 1 : -1;
                })[0],
            buttons
                .filter(({ index }) => index === 2)
                .sort(({ priority: a }, { priority: b }) => {
                    const priorityA = a(state);
                    const priorityB = b(state);
                    if (priorityA === priorityB) {
                        return 0;
                    }
                    return priorityA > priorityB ? 1 : -1;
                })[0],
        ];
    }, [playing, buttons, state]);
    return (
        <div className={classNames([styles.container, className])}>
            {currentToolbar.map((button) => {
                const { id, icon = null } = button;
                return (
                    <CircleButton
                        key={`button-${button.id}`}
                        className={styles.button}
                        onClick={(e) => onClick(e, button)}
                    >
                        {icon !== null ? <img src={icon} className={styles.icon} alt={id} /> : null}
                    </CircleButton>
                );
            })}
        </div>
    );
}

Toolbar.propTypes = propTypes;
Toolbar.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default animatedState(Toolbar);
